section.error{
  flex-grow: 1;
  min-height: 100%;
  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    text-align: center;


    .grid{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: var(--spacing7);
      margin-top: var(--spacing8);
      @media (max-width: 1000px){
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 700px){
        grid-template-columns: repeat(2, 1fr);
      }

      .category{
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon{
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 4rem;
          height: 4rem;
          padding: var(--spacing5);

          background-color: var(--blue);
          border-radius: 50%;

          transition: background-color var(--speed1) ease-in-out;
          svg{
            width: 100%;
            height: 100%;
            *{
              fill: var(--white);
            }
          }
        }
        .label{
          margin-top: var(--spacing3);

          font-weight: 700;
          font-size: var(--fontSize3);
          text-align: center;
          @media (max-width: 600px){
            font-size: var(--fontSize2);
          }
        }

        .description{
          max-width: 250px;
          margin-top: var(--spacing3);

          text-align: center;
        }

        &:hover{
          cursor: pointer;
          .icon{
            background-color: var(--green);
          }
        }
      }
    }
  }
}
